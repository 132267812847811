const courses = {
	"São Luís": [
		"MBA em Gestão Empresarial",
		"MBA em Gestão: Finanças, Controladoria e Auditoria",
		"LL.M em Direito Tributário",
		"LL.M em Direito: Civil e Processual Civil",
		"MBA em Gestão: Projetos",
		"MBA em Gestão Comercial",
		"MBA em Gestão: Pessoas e Liderança",
	],
	Imperatriz: [
		"MBA em Gestão Empresarial",
		"MBA em Gestão: Finanças, Controladoria e Auditoria",
		"LL.M em Direito Tributário",
		"LL.M em Direito: Civil e Processual Civil",
		"MBA em Gestão: Projetos",
		"MBA em Gestão Comercial",
		"MBA em Gestão: Pessoas e Liderança",
		"MBA Executivo em Economia e Gestão: Agronegócio",
	],
}

function updateCourses() {
	const localidadeSelect = document.getElementById("localidade")
	const cursoSelect = document.getElementById("curso")
	const selectedLocalidade = localidadeSelect.value

	// Clear current options
	cursoSelect.innerHTML = '<option value="">Selecione o curso</option>'

	if (selectedLocalidade && courses[selectedLocalidade]) {
		courses[selectedLocalidade].forEach((course) => {
			const option = document.createElement("option")
			option.value = course
			option.textContent = course
			cursoSelect.appendChild(option)
		})
	}
}

// Attach the event listener to the select element
document.getElementById("localidade").addEventListener("change", updateCourses)

$(".courses_slider").slick({
	arrows: true,
	dots: false,
	infinite: false,
	prevArrow: $(".prev"),
	nextArrow: $(".next"),
	adaptiveHeight: true,
})
$(".courses_slider-mobile").slick({
	arrows: true,
	dots: false,
	infinite: false,
	prevArrow: $(".prev"),
	nextArrow: $(".next"),
	adaptiveHeight: true,
})
